.layout-container {
  
}

.header {
  height: 111px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;

  .logo-container{
    padding: 24px;

    .logo-image{
      width: 54px;
      height: 62px;
    }
    .logo-text{
      margin: 0px 15px;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: #000000;
    }
  }

  .nav-links{
    .nav-link{
      margin-right: 101px;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      text-align: center;
      color: #000000;

      a{
        color: black;
        text-decoration: none;
      }
    } 
  }
}

.homepage-first-section{
  margin-top: 111px;
  background-image: url('../../public/images/homepage_abstract_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
  box-sizing: border-box;
  display:grid;
  place-items: center;

  .banner-text-container{
    background: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.55;
    border-radius: 18px;
    padding: 30px 130px;
    
    .banner-text-1{
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 500;
      font-size: 36.0872px;
      line-height: 42px;
      letter-spacing: 0.715em;
      color: #000000;
      margin-bottom: 19px;
    }

    hr{
      width: 326px;
      height: 4px;
      left: 599px;
      top: 414px;
      background: #1194D6;
    }

    .banner-text-2{
      margin-top: 37px;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 71.2943px;
      line-height: 84px;
      letter-spacing: 0.715em;
      color: #000000;
    }

    .banner-text-3{
      margin-top: 35px;
      font-family: 'Raleway';
      font-style: normal;
      font-weight: 500;
      font-size: 20.1545px;
      line-height: 24px;
      letter-spacing: 0.715em;
      color: #000000;
    }
  } 
}

.homepage-second-section{
  padding: 38px 222px;
  display: grid;
  place-items: center;

  .about-section-header{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.39em;
    color: #000000;
  }

  hr{
    margin-top: 26px;
    width: 326px;
    height: 4px;
    left: 599px;
    top: 949px;
    background: #1194D6;
  }

  .about-section-content{
    margin-top: 37px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
  }
}

.homepage-third-section{
  width: 100%;
  height: 537px;
  display: flex;

  .third-section-left{
    background-image: url('../../public/images/third-section-left.png');
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    display: grid;
    position: relative;
    place-items: center;

    .third_section_left_image{
      position: absolute;
      left: 121px;
      top: 80px;
      z-index: 1;
    }

    .third-section-left-content{
      font-family: 'Raleway';
      font-style: normal;
      z-index: 2;
      font-weight: 500;
      font-size: 49.5435px;
      line-height: 58px;
      text-align: center;
      letter-spacing: 0.19em;
      margin: 0px 200px;
    }
  }
  .third-section-right{
    background-image: url('../../public/images/third-section-right.png');
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.homepage-fourth-section{

  .fourth-section-header{
    margin-top: 67px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.39em;
    color: #000000;
  }

  hr{
    margin-top: 26px;
    width: 326px;
    height: 4px;
    left: 599px;
    top: 949px;
    background: #1194D6;
  }

  .fourth-section-row-container{
    margin: 70px 130px;
    display: flex;
    justify-content: space-between;

    .fourth-section-row-item{
      display: grid;
      place-items: center;
      margin-top: 100px;
      margin-left: 60px;
      margin-right: 60px;

      .fourth-section-icon{
        width: 170px;
        height: 170px;
      }
  
      .fourth-section-title{
        margin-top: 55px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #1194D6;
      }
  
      .fourth-section-content{
        margin-top: 40px;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #000000;
      }
    }

  }
}

.homepage-reach-out-section{
  width: 100%;
  padding: 50px 0px;
  background: #1194D6;
  display: grid;
  place-items: center;

  .reach-out-section-title{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.39em;
    color: #FFFFFF;
  }

  .reach-out-section-content{
    margin-top: 25px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
  }

  .contact-button{
    margin-top: 30px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 21.9503px;
    line-height: 26px;
    letter-spacing: 0.39em;
    color: black;
    border-radius: 50px;
    padding: 20px;
    outline: none;
    border: none;
    background-color: white;
    cursor: pointer;
  }
}

.homepage-bottom-section{
    display: grid;
    border: 2px red solid;
    place-items: center;
    padding: 30px 0px;

  .logo-container{
    display: flex;
    margin-top: 50px;
    align-items: center;
    .logo-image{
      width: 95px;
      height: 110px;
    }
    .logo-text{
      font-family: 'Raleway';
      margin: 0px 25px;
      font-style: normal;
      font-weight: 700;
      font-size: 38.8865px;
      line-height: 46px;
      color: #000000;
    }
  }
  .copyright-text{
    margin-top: 55px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
  }

}